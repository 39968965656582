import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/apps/dop-employee-portal/components/Providers/AppProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/apps/dop-employee-portal/mocks/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/tmp/app/node_modules/.pnpm/@tanstack+react-query@5.51.11_react@19.0.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/jotai@2.10.3_@types+react@19.0.1_react@19.0.0/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/tmp/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.8_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.69.5/node_modules/next/font/local/target.css?{\"path\":\"../../packages/fonts/nextjs.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./Pretendard-1.3.9/web/variable/woff2/PretendardVariable.woff2\",\"display\":\"swap\",\"weight\":\"45 920\",\"variable\":\"--font-pretendard-variable\"}],\"variableName\":\"PretendardVariable\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/auth-provider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/packages/ui-react/app/providers/ui/dop-ui-root-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nText"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/i18n-text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/translations-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/use-translation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/shared/lib/react-query/query-client-provider.tsx");
